@tailwind base;
@tailwind components;
@tailwind utilities;

@import "./customTailwind/main.scss";

@font-face {
  font-family: "Tajawal";
  src: url("./../public/assets/fonts/Tajawal/Tajawal-Regular.ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Tajawal";
  src: url("./../public/assets/fonts/Tajawal/Tajawal-Bold.ttf")
    format("truetype");
  font-weight: bold;
  font-style: normal;
}

body {
  @apply bg-gray-50 dark:bg-gray-800;
  font-family: "Tajawal", sans-serif;
  // user-select: none;
}
