nav {
    .dropdown {
        // &:hover{background-color: aqua;}
        .dropdownNavbar {
            display: none;
            position: absolute;
        }

        &:hover {
            .dropdownNavbar {
                display: block;
            }
        }
    }
}