.sidebar-link {
    .tooltip {
        box-shadow: 0px 7px 14px -4px rgba(0,0,0,0.43);
-webkit-box-shadow: 0px 7px 14px -4px rgba(0,0,0,0.43);
-moz-box-shadow: 0px 7px 14px -4px rgba(0,0,0,0.43);
    }
    &.openSmall {
        @apply flex-row;

        div {
            &:first-child {
                @apply flex flex-row;

                h5 {
                    @apply block;
                }

                .counter {
                    @apply visible
                }
            }

            &:nth-child(2) {
                @apply flex;

            }

        }

        .tooltip {
            @apply invisible;
        }
    }

    &.halfOpen {
        @apply flex-row-reverse;

        div {
            &:first-child {
                @apply flex flex-row mx-auto;

                h5 {
                    @apply hidden;
                }

                .counter {
                    @apply invisible
                }
            }

            &:nth-child(2) {
                @apply invisible;

            }

        }

        &:hover {
            .tooltip {
                @apply visible;
            }
        }

    }

    &.open {
        @apply flex-row;

        div {
            &:first-child {
                @apply flex
            }

            .counter {
                @apply visible
            }
        }
    }
}